<template>
    <!-- 头部组件 -->
    <div class="jxw_nav">
        <el-row :gutter="10">
            <!-- logo -->
            <el-col :span="4">
                <router-link to="/" class="logo">
                    <img :src="require('@/assets/logo.png')" alt="">
                </router-link>
            </el-col>
            <!-- nav -->
            <el-col :span="16" style="text-align: center">
                <header-nav></header-nav>
            </el-col>
            <!-- search & login -->
            <el-col :span="4">
                <div class="jxw_nav_right">
                    <!-- 登录成功 -->
                    <div class="jxw_nav_is_login"
                        v-if="is_login">
                        <!-- 个人中心 -->
                        <router-link to="/center">
                            <span>欢迎您，</span>
                            <span>{{user_name}}</span>
                        </router-link>
                        <!-- 注销 -->
                        <a href="javascript:;"
                            @click="handleLogout">
                            <span>退出</span>
                        </a>
                    </div>
                    <!-- 登录区域 -->
                    <el-button 
                        type="warning" 
                        round
                        @click="$router.push('/login')"
                        v-else>
                        <span>登录&nbsp;/&nbsp;注册</span>
                    </el-button>
                    <!-- <router-link 
                        to="/login" 
                        class="login_area"
                        v-else>
                        <span class="glyphicon glyphicon-user"></span>
                        <span class="login">登录&nbsp;/&nbsp;免费注册</span>
                    </router-link> -->
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    computed: mapState({
        is_login: state => state.user.is_login,
        user_name: state => state.user.user_name,
    }),
    components: {
        headerNav: () => import("@/components/headerNav")
    },
    methods:{
        ...mapActions([
            "postLogout"
        ]),
        handleLogout(){
            this.postLogout().then((res) => {
                this.$message({
                    showClose: true,
                    message: res,
                    type: "success"
                })
                setTimeout(() => {
                    this.$router.replace("/login")
                },1000)
            }).catch(() => {})
        }
    },
}
</script>

<style scoped>
/* 导航栏 */
.jxw_nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
}
/* logo */
.logo{
    display: inline-block;
    width: 100%;
    height: 80px;
    /* padding: 10px 0; */
    text-align: center;
    background: #fff;
}
.logo>img{
    height: 100%;
}
/* 导航栏右侧 */
.jxw_nav_right{
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/* 登录区域 */
.jxw_nav_right>.login_area{
    display: inline-block;
    /* margin-left: 20px; */
}
/* 字体区域 */
.jxw_nav_right>.login_area>span{
    margin-right: 10px;
    color: #fff;
}
/* 登录成功样式 */
.jxw_nav_is_login,
.jxw_nav_is_login>a{
    display: inline-block;
    vertical-align: middle;
    color: #303133;
}
.jxw_nav_is_login>a:hover{
    color: #E6A23C;
}
.jxw_nav_is_login>a:first-child{
    width: 100px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.jxw_nav_is_login>a:last-child{
    font-weight: bold;
    color: #F56C6C
}
</style>
